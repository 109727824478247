<template>
  <u-dashboard-layout>
    <u-dashboard-page>
      <u-dashboard-panel grow>
        <u-dashboard-panel-content
          :class="{
            'p-0': true,
            'lg:fixed': true,
            'lg:top-0': true,
            'lg:left-0': true,
            'lg:right-0': true,
            'lg:bottom-0': true,
            'grid': true,
            'lg:grid-cols-2': true,
            'items-center': true,
            'overflow-hidden': true,
            'box-border': true,
          }"
        >
          <div
            :class="{
              'grid': true,
              'justify-self-center': true,
              'items-center': true,
              'justify-center': true,
              'justify-items-center': true,
              'w-full': true,
              'p-4': true,
              'max-w-[546px]': true,
            }"
          >
            <div
              :class="{
                'mb-16': isAdShown,
                'w-full': true,
                'flex': true,
                'justify-center': true,
              }"
            >
              <picture
                data-testid="dashboard-link"
                class="w-[33%] cursor-pointer lg:w-3/5"
              >
                <source
                  type="image/png"
                  srcset="/logo.png"
                >
                <nuxt-img
                  src="/logo.webp"
                  alt="The Show Portal"
                  draggable="false"
                  @click="navigateTo('/')"
                />
              </picture>
            </div>
            <div
              v-if="isAdShown"
              :class="{
                'flex': true,
                'relative': true,
                'w-full': true,
                'gap-x-4': true,
                'justify-center': true,
                'rounded': true,
                'p-4': true,
                'pb-[32px]': true,
                'border': true,
                'border-dashed': true,
                'border-gray-300': true,
                'box-border': true,
                'cursor-pointer': true,
              }"
            >
              <picture
                data-testid="sponsor"
                class="w-1/2 object-cover"
              >
                <source
                  type="image/png"
                  srcset="/logo.png"
                >
                <nuxt-img
                  src="/logo.webp"
                  alt="The Show Portal"
                  draggable="false"
                  @click="navigateTo('/')"
                />
              </picture>
              <p class="w-full text-xs">
                This is sponsored content.
              </p>
              <u-badge class="absolute bottom-1 right-1 flex gap-1">
                <u-icon name="i-ph-currency-circle-dollar" />
                Sponsored
              </u-badge>
            </div>
          </div>
          <div
            :class="{
              'grid': true,
              'h-screen': true,
              'w-full': true,
              'p-4': true,
              'pb-[240px]': true,
              'lg:pb-4': true,
              'box-border': true,
              'overflow-hidden': true,
              'overflow-y-scroll': true,
            }"
          >
            <div
              :class="{
                'grid': true,
                'h-fit': true,
                'w-full': true,
                'max-w-[824px]': true,
                'gap-y-8': true,
                'p-4': true,
                'md:p-8': true,
                'pb-16': true,
                'm-auto': true,
                'lg:mx-0': true,
                'lg:my-auto': true,
                'bg-white dark:bg-gray-800': true,
                'shadow-md': true,
                'rounded-md': true,
                'border': true,
                'border-gray-300 dark:border-gray-700': true,
                'box-border': true,
              }"
            >
              <slot />
            </div>
          </div>
        </u-dashboard-panel-content>
      </u-dashboard-panel>
    </u-dashboard-page>
  </u-dashboard-layout>
</template>

<script lang="ts" setup>
const auth = useAuth()
// const { height } = useWindowSize()
// const isAdShown = computed(() => false && height.value > 600)
const isAdShown = ref(false)

watch(
  () => auth.user,
  async (value) => {
    if (value) {
      await navigateTo('/')
    }
  },
)
</script>
